var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"transition",style:('float: left; display: block; height:'+((_vm.showGraph)?'calc(100vH - 300px) !important;':'0pt !important;')+'width: 74% !important; overflow-y: hidden; position: relative; font-size: 12px; border-right: 2px solid #ccf;')},[_c('vuetiful-board',{attrs:{"theme":_vm.classic,"dark-mode":false,"col-num":1,"row-height":"30","layout-editable":!true,"datasets":[
						      {
						          chartInfo: {
						            series: _vm.monthRevenue,
						            options: {
						              chart: {
						                type: 'bar'
						              },
						              title: {
						                text: _vm.label+ ' (Revenue in T'+_vm.currency+')',
						                align: 'center',
						                style: {
						                  fontSize: '16px',
						                  fontWeight: 'normal',
						                },
						              },
						             labels: _vm.labels,
						              fill: {
						                opacity: .66,
						              },
						              legend: {
						                position: 'bottom',
						              }
						            }
						          },
						          gridInfo: {
						            x: 0, y: 0, w: 6, h: 12, i: '1', static: false
						          },
						        } ]}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }