<template>
<div>
<div class='progressR' :style="bg(percentage)+'; fontSize: '+fontSize+'; color: '+color+';border: 1px solid '+borderColor+'; height: '+height+'pt; width: '+width+'pt'">
	{{Math.round(percentage*1000)/1000}}%
</div>
</div>
</template>
<script>
import { isDark } from '@/AppStyle.js';
export default {
  props: {
	  percentage: { type: Number, default: 0.000 },
	  borderColor: { type: String, default: "#aaa" },
      max: { type: Number, default: 100.000 },
      width: { type: Number, default: 83 },
      height: { type: Number, default: 18 },
      blocker: { type: Number, default: 0},
  	  issues: { type: Number, default: 0},
  },
 components : {
    
  },
  data () {
    return {
       color: "#fff",
       fontSize: Math.round(this.height/2)+2
    }
  },
  methods: {
	  bg( value )
	  {
		  let p1 = Math.round(value/this.max * 100);
		  let col = "#0d0";
		  if ( value > this.max )
		  {
			  col="#b00";
		  }
		  if ( this.blocker )
		  {
			  col="#b00";
		  }
		  else if ( this.issues )
		  {
			  col="#dd0";
		  }
		  this.color = isDark(sessionStorage.bgColor)?"#fff":"#000";
		  if ( p1 > 40 )
		  {
			  this.color = isDark(col)?"#fff":"#000";
		  }
		  //return "background: linear-gradient(to right, #2c2 "+p1+"%, white "+(100-p1)+"%)";
		  return "background: linear-gradient(to right, "+col+" "+p1+"%, "+sessionStorage.bgColor+" "+(p1)+"%)";
	  }
  }
 }
</script>
<style lang="scss" scoped>
.progressR {
    font-weight: bold;
    position: relative;
    font-size: 7pt;
    text-align: center;
    align-items: center;
    justify-content: top;
}
.progressXx:after {
    content: '\A';
    position: absolute;
    opacity: 0.5;
    background-color: #2c2;
    top: 0; bottom: 0;
    left: 0; 
    width: 50%; 
}

</style>