<template>
<div>   
	<div v-if="data && ((Array.isArray( data) && data.length > 0) || data.label)" class='infoCard'  
	     :style="getAppStyle()+'border-radius: '+attr.rounding+'px; box-shadow: '+attr.shadow1+'px '+attr.shadow1+'px '+attr.shadow2+'px 0 '+attr.shadowColor+';'"
	            @mouseenter='enter()'
			    @mouseleave='exit()'>
    	  <div class='myHeader' @click='test'>{{name}}</div> 
    	  <div v-if="!modules || modules.includes(module) || modules.includes(named)">
	    	  <div v-if="Array.isArray( data) && data.length > 1">
		    	  <div :class="'transition '+((!showDetails)?'bigNumber':'invisible')">{{data.length}}</div>
		    	  <div :class="'transition '+((showDetails)?'infoSmall':'invisible')">
		    	  	<div :title='d.label'  class='ddItem' v-for="(d,i) in data" :key="'i_'+i" @click='$emit("open", module, d)'>
		    	  		{{d.label}}
		    	  	</div>
		    	  </div>
		      </div>
		      <div v-else-if="Array.isArray( data)"  class='infoSmall'>
		     		<div :title='data[0].label' class='ddMItem' @click='$emit("open", module, data[0])'>
		    	  		{{data[0].label}}
		    	  	</div>
		      </div>
	    	  <div v-else class='infoSmall'>
		     		<div :title='data.label' class='ddMItem' @click='$emit("open", module, data)'>
		    	  		{{data.label}}
		    	  	</div>
		      </div>
	      </div>
	      <div v-else style='color: #aaa;'>
	    	  <div v-if="Array.isArray( data) && data.length > 1">
		    	  <div :class="'transition '+((!showDetails)?'bigNumber':'invisible')">{{data.length}}</div>
		    	  <div :class="'transition '+((showDetails)?'infoSmall':'invisible')">
		    	  	<div :title='d.label'  class='ddItem' v-for="(d,i) in data" :key="'i_'+i">
		    	  		{{d.label}}
		    	  	</div>
		    	  </div>
		      </div>
		      <div v-else-if="Array.isArray( data)"  class='infoSmall'>
		     		<div :title='data[0].label' class='ddMItem'>
		    	  		{{data[0].label}}
		    	  	</div>
		      </div>
	    	  <div v-else class='infoSmall'>
		     		<div :title='data.label' class='ddMItem'>
		    	  		{{data.label}}
		    	  	</div>
		      </div>
	      </div>
    </div>
    
</div>   
</template>
<script>
import { formatNumber} from '@/variables.js';
import { tr } from '@/translate.js';
import { getAppStyle, setDarkMode } from '@/AppStyle.js';
export default {
  name: "ShowInfoDataDashboard",
  props: {
	data: {
      type: Object,
      default: new Object()
    },
    modules: {
        type: Array,
        default: null
      },
    attr: {
    	type: Object,
        default: new Object()
    },
    name: {
        type: String,
        default: 'WhatAmI'
      },
      module: {
          type: String,
          default: ''
        },
        named: {
            type: String,
            default: 'xxx'
          },
  	 height: {
      type: Number,
      default: 75
    }
  },
 components : {
    
  },
  data () {
    return {
      width: 66,
      showDetails: false,
      tr, formatNumber, getAppStyle
    }
  },

 methods: {
		enter()
		{
			this.showDetails = true;
		},
		test()
		{
			//alert( JSON.stringify(this.modules))
		},
		exit()
		{
			this.showDetails = false;
		}
 }
}
</script>
<style lang="scss" scoped>
 .transition {
 	transition: all .8s;
   -webkit-transition: all .8s;
 }
 
.ddItem { cursor: pointer; white-space: nowrap; font-size: 10px; padding-left: 10pt; }
.ddItem:hover { background-color: #888 !important; color: #fff; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7); }
.ddMItem { cursor: pointer; white-space: nowrap; overflow-x: hidden; text-overflow: ellipsis;font-weight: bold; font-size: 12px; margin-top: 9pt; padding-left: 10pt; }
.ddMItem:hover { background-color: #888 !important; color: #fff; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7); }
.bigNumber{
    display: flex; 
    width: 100% !important; 
    align-items: center; 
    justify-content: center;  
    font-size: 24pt;
}
.myHeader{
	padding-left: 8px; 
	padding-top: 2pt;
	font-size: 8pt;
}
.infoCard:hover {
	background-color: #dfd;
}
.invisible{
	visibility: hidden;
    opacity: 0;
    //width: 0pt !important;
    height: 0pt !important;
    -webkit-transform: scale(0.0);
        -ms-transform: scale(0.0);
        transform: scale(0.0);
    transition: all .5s;
   -webkit-transition: all .5s;
}
.infoSmall{
 	font-size: 9px;
 	padding-left: 6px;
 	height: 50px !important;
 	overflow-y: auto; 
 	overflow-x: hidden; 
 	text-overflow: ellipsis;
}
.infoCard{
	float: left; 
	//border: 1pt solid grey; 
	font-size: 10pt; 
	width: 12% !important; 
	margin-right: 8pt;
	margin-top: 4pt;
	margin-left: 3pt;
	border-radius: 9px;
	height: 80px; 
	display: block;
	padding-right: 5pt;
	background-color: #fff;
	box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.66);
}
</style>