var wbdm_data = {};

export function store( obj )
{
	wbdm_data = obj;
} 


export function restore( )
{
	return wbdm_data;
} 