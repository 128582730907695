<template>
<div>   
	<div class="transition" :style="'float: left; display: block; height:'+((showGraph)?'calc(100vH - 300px) !important;':'0pt !important;')+'width: 74% !important; overflow-y: hidden; position: relative; font-size: 12px; border-right: 2px solid #ccf;'">
		 			<vuetiful-board 
						    :theme="classic"
						    :dark-mode="false"
						    :col-num="1"
						    row-height=30
						    :layout-editable="!true"
						    :datasets="[
						      {
						          chartInfo: {
						            series: monthRevenue,
						            options: {
						              chart: {
						                type: 'bar'
						              },
						              title: {
						                text: label+ ' (Revenue in T'+currency+')',
						                align: 'center',
						                style: {
						                  fontSize: '16px',
						                  fontWeight: 'normal',
						                },
						              },
						             labels: labels,
						              fill: {
						                opacity: .66,
						              },
						              legend: {
						                position: 'bottom',
						              }
						            }
						          },
						          gridInfo: {
						            x: 0, y: 0, w: 6, h: 12, i: '1', static: false
						          },
						        },
						    ]"
						  />	
 				</div>
    
</div>   
</template>
<script>
import { formatNumber} from '@/variables.js';
import { tr } from '@/translate.js';

export default {
  name: "BarChartDataDashboard",
  props: {
	  
	monthRevenue: {
      type: Array,
      default: new Array()
    },
    labels: {
      type: Array,
      default: new Array()
    },
    label: {
        type: String,
        default: ""
      },
    currency: {
          type: String,
          default: ""
        },
    showGraph: {
        type: Boolean,
        default: false
     },
  	 height: {
      type: Number,
      default: 75
    }
  },
 components : {
    
  },
  data () {
    return {
      width: 66,
      showDetails: false,
      tr, formatNumber
    }
  },

 methods: {
		
 },
 created() {
	
 }
}
</script>
<style lang="scss" scoped>
 .transition {
 	transition: all .5s;
   -webkit-transition: all .5s;
 }

</style>